<template>
    <div id="addressBody">
        <!-- 导航栏 -->
        <van-nav-bar class="top" title="修改地址" left-arrow @click-left="onClickLeft" />
        <div class="massage">
            <div style="width: 14px;height: 14px; display: inline-block;margin-right: 10px;">
                <img src="./warning.png" alt="">
            </div>
            <span>付款后只能修改一次，如若商品已发货，运费变更等原因会导致修改失败，请谅解</span>
        </div>
        <div class="oldAddress">
            <!-- <span>{{ editUserAddress }}</span> -->
            <p class="oldAddress-one">原地址</p>
            <p class="oldAddress-two">{{ buyUser.address || '' }}</p>
            <div>
                <span>{{ buyUser.userName || '' }}</span>
                <span>{{ buyUser.telNum || '' }}</span>
            </div>
        </div>
        <div class="addressList">
            <div class="addressList-one">
                <p class="addressList-one-choose">选择的收货地址</p>
                <div>
                    <van-button @click="addNewAddress" size="small" round color="#3E85FF" plain type="info">
                        <span style="color: #3E85FF;">添加新地址</span>
                    </van-button>
                </div>
            </div>
            <div class="addressList-two">
                <van-radio-group v-model="radio">
                    <van-cell-group>
                        <van-cell v-for="(item) in addressList" :key="item.id" clickable @click="chooseAddress(item.id)">
                            <template #title>
                                <span class="custom-title">{{ item.userName || '' }}</span>
                                <span class="custom-title">{{ item.telNum || '' }}</span>
                                <p class="custom-title">{{ item.provinceName + item.cityName + item.countyName +
                                    item.detailInfo }}
                                </p>
                            </template>
                            <template #right-icon>
                                <van-radio checked-color="#e94165" :name=item.id></van-radio>
                            </template>
                        </van-cell>
                    </van-cell-group>
                </van-radio-group>
            </div>
        </div>
        <div style="height: 2rem;"></div>
        <van-tabbar class="footer" :fixed="true">
            <van-button :disabled="isDisabled" color="#F52556" block round type="info" @click="submitBtn">
                <span style="font-size: 0.4054rem;">提交修改</span></van-button>
        </van-tabbar>
    </div>
</template>

<script>
import { getOrderIdDetail, getIdDetailYD, orderDetail, getAllAddress, applyEditAddress } from "@/api/editAddress/editAddress";
import { Toast } from "vant";
export default {
    data() {
        return {
            isDisabled: false,
            radio: '0',
            radio1: '0',
            beforeRoute: '',
            buyUser: {},
            addressList: [],
            orderId: '',//订单id
            editUserAddress: {
                userName: '',
                telNum: '',
                address: '',
                orderId: ''
            },//修改后地址
            timer: null,
            timer1: null
        }
    },
    mounted() {
        let screenWidth = document.documentElement.getBoundingClientRect().width;
        document.documentElement.style.fontSize = screenWidth / 10 + "px";
        const session = this.$route.query.session
        if (session) {
            this.$store.commit('setSession', session)
        }
        this.getPage()
        this.getAllAddress()
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            //  这里的vm指的就是vue实例，可以用来当做this使用
            vm.fromPath = from.path  //获取上一级路由的路径
            vm.beforeRoute = from.path
        })
    },
    activated() {
        this.getPage()
        this.getAllAddress()
    },
    methods: {
        //添加新地址
        addNewAddress() {
            this.$router.push({
                path: "/addAddress",
            });
        },
        //选择某个地址
        chooseAddress(val) {
            this.addressList.map(item => {
                if (item.id == val) {
                    this.editUserAddress.userName = item.userName
                    this.editUserAddress.telNum = item.telNum
                    this.editUserAddress.address = item.provinceName + item.cityName + item.countyName + item.detailInfo
                }
            })
            // console.log(val);
        },
        //提交修改申请
        submitBtn() {
            if (this.editUserAddress.userName == this.buyUser.userName && this.editUserAddress.address == this.buyUser.address && this.editUserAddress.telNum == this.buyUser.telNum) {
                this.isDisabled = true
                this.timer = setTimeout(() => {
                    this.onClickLeft()
                }, 500)
            } else if (this.editUserAddress.userName == '' || this.editUserAddress.telNum == '' || this.editUserAddress.address == '') {
                this.isDisabled = true
                this.timer = setTimeout(() => {
                    this.onClickLeft()
                }, 500)
            } else {
                this.isDisabled = true
                applyEditAddress(this.editUserAddress).then(res => {
                    if (res.data.ok) {
                        Toast.success('修改成功')
                        this.timer = setTimeout(() => {
                            this.onClickLeft()
                        }, 500)
                    } else {
                        Toast(`${res.data.message}`)
                        this.timer1 = setTimeout(() => {
                            this.onClickLeft()
                        }, 500)
                    }
                }).catch(() => {
                    this.isDisabled = false
                    Toast.fail('修改失败请联系管理员')
                })

            }
        },
        // 获取已有地址
        getPage() {
            console.log(this.$route.query);
            this.editUserAddress.orderId = this.$route.query.id;
            if (this.$route.query.shopType == '0') {
                getOrderIdDetail({ id: this.editUserAddress.orderId, types: "1" })
                    .then((res) => {
                        if (res.status === 200) {
                            this.buyUser = res.data.data.getOrderInfo
                            console.log(res.data.data);
                        }
                    })
                    .catch((error) => {
                        Toast.fail("加载失败,请退出重试", error);
                    });
            } else if (this.$route.query.shopType == '3') {
                getIdDetailYD({ id: this.editUserAddress.orderId, types: "1" })
                    .then((res) => {
                        if (res.status === 200) {
                            this.buyUser = res.data.data.getOrderInfo
                            console.log(res.data.data);
                        }
                    })
                    .catch((error) => {
                        Toast.fail("加载失败,请退出重试", error);
                    });
            } else if (this.$route.query.shopType == '1') {

                orderDetail(this.editUserAddress.orderId)
                    .then((res) => {
                        if (res.status === 200) {
                            this.buyUser = res.data.data.orderLogistics
                            console.log(res.data.data);
                        }
                    })
                    .catch((error) => {
                        Toast.fail("加载失败,请退出重试", error);
                    });
            }
        },
        //获取全部地址
        getAllAddress() {
            getAllAddress().then(res => {
                if (res.data.code == 0) {
                    this.addressList = res.data.data.records
                    this.addressList.map(item => {
                        if (item.isDefault == '1') {
                            this.radio = item.id
                            this.radio1 = item.id
                            this.editUserAddress.userName = item.userName
                            this.editUserAddress.address = item.provinceName + item.cityName + item.countyName + item.detailInfo
                            this.editUserAddress.telNum = item.telNum
                        }
                    })
                }
                // console.log(res);
            })
        },
        //返回原生不刷新,要加判断
        backToHere() {
            let u = navigator.userAgent;
            let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; //android
            let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
            if (isIOS) {
                window.webkit.messageHandlers.mallChangeAddress.postMessage("");
            }
            if (isAndroid) {
                // Toast('?????')
                window.android.mallChangeAddress();
            }
        },
        //回退
        onClickLeft() {
            // console.log(this.beforeRoute, '99999');
            if (this.beforeRoute === '/orderIdDetail' || this.beforeRoute === '/yundian/orderIdDetail' || this.beforeRoute === '/miliOrderInfo') {
                this.$router.back(-1)
            } else {
                this.backToHere()

            }
        },
    },
    destroyed() {
        clearTimeout(this.timer)
        clearTimeout(this.timer1)
    }
}
</script>

<style lang="less" scoped>
#addressBody {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    background-color: #f6f6f6;

    .top {
        height: 6%;
        font-size: 0.4865rem;
        color: #000000;
        background-color: #ffffff;
    }

    .massage {
        margin-top: 0.13rem;
        padding: 0.43rem 0.37rem;
        color: #F57525;
        background-color: #FFF3EA;
    }

    .oldAddress {
        margin-top: 0.13rem;
        padding: 0.43rem 0.37rem;
        background-color: #ffffff;

        .oldAddress-one {
            font-size: 14px;
            color: #333333;
            font-weight: 600;
            margin-bottom: 0.4rem;
        }

        .oldAddress-two {
            margin-bottom: 6px;
        }

        .oldAddress-one {
            font-size: 14px;
            color: #333333;
            font-weight: 600;
            margin-bottom: 0.4rem;
        }
    }

    .addressList {
        margin-top: 0.13rem;
        padding: 0.43rem 0.37rem;
        background-color: #ffffff;

        .addressList-one {
            display: flex;
            justify-content: space-between;

            .addressList-one-choose {
                font-size: 14px;
                color: #333333;
                font-weight: 600;
                line-height: 28px;
            }
        }

        .addressList-two {
            .van-cell {
                padding: 10px 0;
            }
        }
    }

    .footer {
        box-sizing: border-box;
        margin-top: 0.13rem;
        padding: 0 0.43rem;
        background-color: #ffffff;
    }

}
</style>