import https from '@/router/https'
//获取红尘坊订单收货地址
export const getOrderIdDetail = (params) => {
    return https.fetchGet('/mallapp/orderinfo/FlOrderDetails', params);
}
//获取侠客集市订单收货地址
export const getIdDetailYD = (params) => {
    return https.fetchGet('/mallapp/orderinfo/YDOrderDetails', params);
}
// 获取鬼市订单收货地址
export const orderDetail = (id) => {
    return https.fetchGet('/mallapp/orderinfo/getRiceOrderDetails?orderId=' + id)
}
//获取全部地址
export const getAllAddress = (param) => {
    return https.fetchGet('/mallapp/useraddress/page', param);
}
//申请修改地址
export const applyEditAddress = (data) => {
    return https.fetchPost('/mallapp/orderinfo/modificationAddress', data);
}